<template>
  <div class="text-end">
    <div class="btn-group">
      <button
        @click="addVoter"
        v-if="hasRequiredRole(['admin', 'web-voter-add'])"
        class="btn btn-success"
      >
        <i class="bi bi-plus"></i> Add Voter
      </button>
      <div class="dropdown ms-1" v-if="hasRequiredRole(['admin', 'web-export'])">
        <!-- Dropdown toggle button -->
        <button
          class="btn btn-success dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bi bi-file-earmark-spreadsheet-fill"></i> Export
        </button>

        <!-- Dropdown menu -->
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li>
            <a class="dropdown-item" href="#" @click="exportDefault"
              ><i class="bi bi-file-earmark-spreadsheet-fill"></i> Default List</a
            >
          </li>
          <li>
            <a class="dropdown-item" href="#" @click="exportGrouped"
              ><i class="bi bi-file-earmark-spreadsheet-fill"></i> Grouped List</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <form
    @submit.prevent="handleSubmit"
    style="overflow-y: auto; max-height: calc(100vh-120px)"
  >
    <div class="table-responsive">
      <table class="table align-middle">
        <thead>
          <tr>
            <th class="text-center" style="min-width: 300px">Sector</th>
            <th class="text-center" style="min-width: 300px">Name</th>
            <th class="text-center">Precinct</th>
            <th class="text-center">Gender</th>
            <th class="text-center">Municipality</th>
            <th class="text-center">Brgy</th>
            <th class="text-center">Sitio</th>
            <th class="text-center">BOMTE</th>
            <th class="text-center">Leader</th>
            <th class="text-center">Sub-Leader</th>
            <th class="text-center">Type</th>
            <th class="text-center">Remarks</th>
            <th class="text-center">Supporter</th>
            <th class="text-center">Class</th>
            <th class="text-center">First Scan</th>
            <th class="text-center">Second Scan</th>
          </tr>
          <tr>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.title_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.name_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.precinct_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.gender_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th style="min-width: 200px">
              <div class="input-group">
                <select
                  @change="onCityChanged"
                  class="form-select"
                  v-model="searchModel.cityid_voter"
                >
                  <option v-for="city in cities" :key="city.id_mun" :value="city.id_mun">
                    {{ city.name_mun }}
                  </option>
                </select>
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th style="min-width: 200px">
              <div class="input-group">
                <select
                  @change="onBrgyChanged"
                  class="form-select"
                  v-model="searchModel.brgyid_voter"
                >
                  <option v-for="brgy in brgys" :key="brgy.id_brgy" :value="brgy.id_brgy">
                    {{ brgy.name_brgy }}
                  </option>
                </select>
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.sitio_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.pleader_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.leader_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.subleader_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.type_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.remarks_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.side_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.loyaltystate_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.gift1_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
            <th>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchModel.gift2_voter"
                />
                <button type="submit" class="btn btn-sm btn-secondary d-lg-none">
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            @click.prevent="getInfo(voter)"
            style="cursor: pointer"
            :class="[{ 'table-danger': voter.side_voter === false }]"
            v-for="voter in voters"
            :key="voter.sypsk_voter"
          >
            <td>{{ voter.title_voter }}</td>
            <td>
              {{ voter.name_voter }}
            </td>
            <td>{{ voter.precinct_voter }}</td>
            <td>{{ voter.gender_voter }}</td>
            <td>{{ voter.municipality_voter }}</td>
            <td>{{ voter.brgy_voter }}</td>
            <td>{{ voter.sitio_voter }}</td>
            <td>{{ voter.pleader_voter }}</td>
            <td>{{ voter.leader_voter }}</td>
            <td>{{ voter.subleader_voter }}</td>
            <td>{{ voter.type_voter }}</td>
            <td>{{ voter.remarks_voter }}</td>
            <td class="text-center">
              <i
                v-if="voter.side_voter === true"
                class="bi bi-check text-success fs-3"
              ></i>
              <i v-else class="bi bi-x text-danger fs-3"></i>
            </td>
            <td>{{ voter.loyaltystate_voter }}</td>
            <td class="text-center">
              <i
                v-if="voter.gift1_voter === true"
                class="bi bi-check2-square text-success fs-3"
              ></i>
              <i v-else class="bi bi-square text-dark fs-3"></i>
            </td>
            <td class="text-center">
              <i
                v-if="voter.gift2_voter === true"
                class="bi bi-check2-square text-success fs-3"
              ></i>
              <i v-else class="bi bi-square text-dark fs-3"></i>
            </td>
            <td>
              <span v-if="voter.side_voter === true">
                <i
                  v-if="voter.prevstate_voter === 2"
                  class="bi bi-check2-all text-success fs-3"
                ></i>
                <i v-else class="bi bi-check2 text-success fs-3"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <button type="submit" class="d-none" />
    </div>
    <PaginationView
      v-if="voters !== null && voters.length !== 0"
      @page-change="handleChange"
      :page="searchModel.currentPage"
      :pages="searchModel.totalPages"
      :items="searchModel.totalItems"
    />
  </form>
  <FullscreenModal title="Voter Information" @close="toggleModal" :show="showModal">
    <VoterInfo :id="currentId" @next="handleNext" @prev="handlePrev" @reset="onReset" />
  </FullscreenModal>
</template>

<script>
import spinnerService from "@/services/spinnerService";
import toastService from "@/services/toastService";
import voterService from "@/services/voterService";
import { ref, onMounted, watch, defineComponent } from "vue";
import PaginationView from "@/components/PaginationControl.vue";
import areaService from "@/services/areaService";
import FullscreenModal from "@/components/modals/FullscreenModal.vue";
import VoterInfo from "@/components/voters/VoterInfo.vue";
import { hasRoles } from "@/utils/auth";
import store from "@/store";

export default defineComponent({
  components: {
    PaginationView,
    FullscreenModal,
    VoterInfo,
  },
  emits: ["voter-selected", "selection-changed", "add-voter"],
  setup(_, { emit }) {
    const searchModel = ref({
      name_voter: "",
      totalPages: 10,
      currentPage: 1,
      totalItems: 500,
    });
    const currentIndex = ref(-1);
    const initSearchModel = ref(null);
    const voters = ref(null);
    const cities = ref([]);
    const brgys = ref([]);
    const showModal = ref(false);
    const nextInvoked = ref(false);
    const prevInvoked = ref(false);
    const toggleModal = () => {
      showModal.value = !showModal.value;
    };

    const hasRequiredRole = (requiredRoles) => {
      return hasRoles(requiredRoles);
    };

    const currentId = ref(0);

    const handleNext = () => {
      if (currentIndex.value < voters.value.length - 1) {
        currentIndex.value += 1;
        currentId.value = voters.value[currentIndex.value].syspk_voter;
      }
    };
    const handlePrev = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1;
        currentId.value = voters.value[currentIndex.value].syspk_voter;
      }
    };
    const onReset = () => {
      currentId.value = -1;
      currentIndex.value = -1;
    };
    const onSelectionChanged = (selection) => {
      currentId.value = selection.id;
      if (selection.direction === "next") {
        nextInvoked.value = false;
      } else {
        prevInvoked.value = false;
      }
    };

    const resetSelection = () => {
      emit("voter-selected", { id: -1, index: -1 });
    };
    const handleSubmit = async () => {
      try {
        resetSelection();
        spinnerService.show();
        initSearchModel.value = { ...searchModel.value };
        searchModel.value.currentPage = 1;
        const response = await voterService.search(searchModel.value);
        searchModel.value.totalPages = response.data.totalPages;
        searchModel.value.totalItems = response.data.totalItems;
        voters.value = response.data.voters;
      } catch (err) {
        toastService.error("An error occured while processing the request");
      } finally {
        spinnerService.hide();
      }
    };
    const getInfo = (voter) => {
      const idx = voters.value.findIndex((x) => x.syspk_voter === voter.syspk_voter);
      currentIndex.value = idx;
      currentId.value = voter.syspk_voter;
      if (idx > -1) {
        toggleModal();
      }
    };
    const addVoter = () => {
      toggleModal();
      currentId.value = 0;
    };

    const handleChange = async (page) => {
      try {
        spinnerService.show();
        searchModel.value.currentPage = page;
        initSearchModel.value.currentPage = page;
        const response = await voterService.search(initSearchModel.value);
        searchModel.value.totalPages = response.data.totalPages;
        searchModel.value.totalItems = response.data.totalItems;
        voters.value = response.data.voters;
      } catch (err) {
        toastService.error("An error occured while processing the request.");
      } finally {
        spinnerService.hide();
      }
    };

    const getMunicipalities = async () => {
      cities.value = await areaService.getMunicipalities();
      if (cities.value !== null && cities.value.length > 0) {
        searchModel.value.municipality_voter = cities.value[0].name_mun;
        await getBrgys();
      }
    };

    const exportDefault = async () => {
      spinnerService.show();
      await voterService.exportVoterDefault(
        store.getters["auth/currentUser"].accessToken,
        "default",
        initSearchModel.value
      );
      spinnerService.hide();
    };
    const exportGrouped = async () => {
      spinnerService.show();
      await voterService.exportVoterDefault(
        store.getters["auth/currentUser"].accessToken,
        "grouped",
        initSearchModel.value
      );
      spinnerService.hide();
    };

    onMounted(() => {
      const storedItem = localStorage.getItem("searchModel");
      if (storedItem) {
        searchModel.value = JSON.parse(storedItem);
        handleSubmit();
      }
      getMunicipalities();
    });
    const onCityChanged = () => {
      getBrgys();
    };
    const onBrgyChanged = () => {
      handleSubmit();
    };
    const getBrgys = async () => {
      brgys.value = await areaService.getBrgys(searchModel.value.cityid_voter);
    };

    // Watch for changes in searchModel and save to localStorage
    watch(
      searchModel,
      (newValue) => {
        localStorage.setItem("searchModel", JSON.stringify(newValue));
      },
      { deep: true }
    );

    return {
      searchModel,
      handleSubmit,
      voters,
      getInfo,
      handleChange,
      addVoter,
      cities,
      brgys,
      onCityChanged,
      onBrgyChanged,
      showModal,
      toggleModal,
      currentId,
      handleNext,
      handlePrev,
      onSelectionChanged,
      onReset,
      hasRequiredRole,
      exportDefault,
      exportGrouped,
    };
  },
});
</script>

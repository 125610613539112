import { apiConfig } from "@/utils/config";
import apiService from "./apiService";
import store from "@/store";
const authRequired = true;
const voterService = {
    async search(searchModel) {
        return await apiService.get(`v/search`, searchModel, authRequired);
    },
    async getInfo(id) {
        try {
            return (await apiService.get(`v/getinfo/${id}`, {}, authRequired)).data;
        }
        catch (err) {
            return {};
        }
    },
    async getLeaders(id) {
        try {
            return (await apiService.get(`v/getleaders/${id}`, {}, authRequired)).data;
        }
        catch {
            return [];
        }
    },
    async getPresets(id) {
        try {
            return (await apiService.get(`v/getPresets/${id}`, {}, authRequired)).data;
        }
        catch {
            return {};
        }
    },
    async getSubleaders(leader) {
        try {
            return (await apiService.get(`v/getsubleaders`, { leadername: leader }, authRequired)).data;
        }
        catch {
            return [];
        }
    },
    async getVoterGroups(id) {
        try {
            return (await apiService.get(`v/getvotergroups/${id}`, {}, authRequired)).data;
        }
        catch {
            return [];
        }
    },
    async getHouseholdContent(id) {
        try {
            return (await apiService.get(`v/getvoterhhcontent/${id}`, {}, authRequired)).data;
        }
        catch {
            return [];
        }
    },
    async saveVoter(voter) {
        return await apiService.post(`v/savevoter`, voter, authRequired);
    },
    async getQrCodesForPrinting(brgy, page) {
        try {
            return (await apiService.get(`p/getprintableids/${brgy}/${page}`)).data;
        }
        catch {
            return [];
        }
    },
    async searchLogs(page, query) {
        try {
            return (await apiService.get(`v/searchlogs/${page}`, query, true)).data;
        }
        catch {
            return [];
        }
    },
    async import(batch){
        try{
            return (await apiService.post(`v/import`,batch,true)).data
        }
        catch{
            return {success: false, message: 'An error occured while processhing the request'};
        }
    },
    async importSupporters(batch,page,totalPages,transId,cityId){
        try{
            return (await apiService.post(`v/import-tagged/${transId}/${page}/${totalPages}/${cityId}`,batch,true)).data
        }
        catch{
            return {success: false, hasNextAction: false, message: 'An error occured while processhing the request'};
        }
    },
    async exportVoterDefault(token, type, searchparams) {
        try {
            const queryString = new URLSearchParams(searchparams).toString();
            const response = await fetch(`${apiConfig.baseUrl}v/export-${type}?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'Authorization': `Bearer ${token}`, // Add the authorization header
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
            const currentUser = store.getters['auth/currentUser'];
            // Get the filename from the response headers if available
            let filename = `${currentUser.name_user}-${timestamp}export.xlsx`;

            // Convert the response to a blob
            const blob = await response.blob();

            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;

            // Append link to the body (required for Firefox)
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error exporting voters:', error);
        }
    },
    async exportVoterGroup(token, type, searchparams) {
        try {
            const queryString = new URLSearchParams(searchparams).toString();
            const response = await fetch(`${apiConfig.baseUrl}v/export-${type}?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'Authorization': `Bearer ${token}`, // Add the authorization header
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
            const currentUser = store.getters['auth/currentUser'];
            // Get the filename from the response headers if available
            let filename = `${currentUser.name_user}-${timestamp}export.xlsx`;

            // Convert the response to a blob
            const blob = await response.blob();

            // Create a link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;

            // Append link to the body (required for Firefox)
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error exporting voters:', error);
        }
    },
    async getVoterByQrCodeAsync(code, mode) {
        try {
            return (await apiService.get(`v/getvoterbyqrcode/${mode}`, { code: code }, true)).data;
        }
        catch(err) {
            return { success: false, message: "An error occured while processing the request." + mode + code }
        }

    },

    async getSectors(){
        try {
            return (await apiService.get(`v/getsectors`, { }, true)).data;
        }
        catch(err) {
            return { success: false, message: "An error occured while processing the request."}
        }
    }

};

export default voterService;